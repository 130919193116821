nav.top-nav {
    padding: 20px 0;
    top: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 1001;
    @include transition(box-shadow .2s, background .2s);
    &.nav-separate {
        background: $color-accent;
    }
    &.nav-scrolled {
        box-shadow: 0px 5px 10px rgba(0,0,0,.1);
        background: $color-accent;
    }
    .navbar-brand {
    	position: relative;
    	z-index: 99;
        color: #fff;
        font-size: 36px;
        font-weight: 700;
        font-family: 'Myriad Pro';
        svg {
            width: 175px;
            height: 60px;
            position: relative;
            top: -5px;
            fill: #fff;
        }
        svg:hover {
        	@include transition(fill .4s);
        	fill: #eeedf5;
        }
	}
	.nav-mid, .nav-mid > ul {
		pointer-events:unset !important;
	}
    .nav-mid, .nav-right {
        ul {
			width: 100%;
            list-style: none;
            text-align: center;
            margin: 0;
			padding: 0;
			display: inline-block;
            > li {
                display: inline-block;
                .nav-dropdown {
                    &:after {
                        content: '';
                        position: absolute;
                        display: inline-block;
                        border: 4px solid transparent;
                        border-top-color: #fff;
                        right: 8px;
                        top: 25px;
                        @include transition(border-top-color .2s);
                    }
                    &.show {
                        &:after {
                            border-top-color: $color-accent-vibrant;
                        }
                    }
                }
                > a, .nav-dropdown > a {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 13px;
                    padding: 17px 0;
                    font-weight: 600;
                    letter-spacing: 2px;
                    margin: 0 20px;
                    position: relative;
                    @include transition(color .2s);
                    &:hover, &:focus {
                        background: transparent;
                        color: $color-accent-vibrant;
                    }
                }
                &.list-separator {
                	display: none;
                }
                &.list-separator {
                    &:after {
                        content: '';
                        display: inline-block;
                        height: 10px;
                        position: relative;
                        top: 0px;
                        width: 2px;
                        background: #fff;
                        opacity: .2;
                    }
				}
				&.nav-item-account, &.nav-item-login, &.nav-item-register {
					display: none;
				}
            }
        }
    }
    .nav-mid {
        .dropdown-menu {
            a.dropdown-item {
                position: relative;
                i.icon {
                    font-size: 24px;
                    position: absolute;
                    top: 20px;
                    left: 10px;
                    @include transition(color .2s);
                }
                .content {
                    padding-left: 30px;
                    .title {
                        font-size: 16px;
                        font-weight: 600;
                        color: $color-accent;
                        @include transition(color .2s);
                    }
                    .subtitle {
                        font-size:12px;
                        opacity:.4;
                    }
                }
                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
                &:hover {
                    .content {
                        .title {
                            color: $color-accent-vibrant;
                        }
                    }
                }
            }
        }
    }
    .nav-right ul {
        text-align: right;
        .nav-search-form {
            position: relative;
            padding-right: 20px;
            .form-control {
                padding: 10px 18px;
                font-size: 14px;
                @include transition(box-shadow .2s ease);
                width: 180px;
                box-shadow: 0px 10px 15px rgba(0,0,0,.08);                
                &:focus {
                    box-shadow: 0px 10px 25px rgba(0,0,0,.1);
                }
            }
            .btn-search {
                padding: 7px 12px;
                position: absolute;
                right: 20px;
                top: 0;
                font-size: 18px;
                color: $color-accent;
                @include transition(color .2s);
                &:hover {
                    color: $color-accent-vibrant;
                }
            }
        }
    }
    ul li a.nav-button {
        padding: 12px 25px;
        border-radius: 4px;
        border: 2px solid $color-accent-vibrant;
        @include transition(all .2s);
        &:hover {
            background: $color-accent-vibrant;
            color: #fff;
            box-shadow: 0px 7px 18px transparentize($color-accent-vibrant, .85);
        }
        .icon {
            font-size: 16px;
            margin-right: 5px;
            position: relative;
            top: 2px;
            @include transition(all .2s);
        }
        &.nav-button-alt {
            border-color: #fff;
            .icon {
                color: #fff;
            }
            &:hover {
                background: #fff;
                color:  $color-accent-vibrant;
                box-shadow: none;
                .icon {
                    color: $color-accent-vibrant;
                }
            }
        }
    }
    .language-dropdown {
        display: inline-block;
        position: relative;
        top: -5px;
        margin-left: 20px;
        > button {
            position: relative;
            text-transform: uppercase;
            cursor: pointer;
            color: #fff;
            font-size: 13px;
            font-weight: 600;
            background: transparent;
            border: none;
            letter-spacing: 2px;
            padding: 5px 10px;
            z-index: 2;
            @include transition(all .2s);
            &:after {
                content: '';
                display: inline-block;
                border: 4px solid transparent;
                border-top-color: $color-accent-vibrant;
                position: relative;
                top: 2px;
                margin-left: 3px;
                @include transition(transform .2s ease-out);
                @include prefix(transform-origin, 50% 12.5%);
            }
            &:focus {
                outline: none;
            }
            .flag-icon {
                width: 15px;
                height: 15px;
                border-radius: 15px;
                position: relative;
                top: -1px;
                margin-right: 10px;
            }
        }
        .dropdown-menu {
            padding: 0;
            width: 100%;
            min-width: 0;
            border: none;
            border-radius: 3px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin-top: 0px;
            display: block;
            visibility: hidden;
            opacity: 0;
            z-index: 1;
            @include transition(opacity .2s, visibility .2s);
            background: transparent;
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: calc(100% + 30px);
                border-radius: 4px;
                background: #fff;
                z-index: -1;
                box-shadow: 0px 10px 20px rgba(0,0,0,.1);
            }
            .dropdown-item {
                padding: 6px 10px;
                color: #acaab5;
                font-size: 13px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 2px;
                .flag-icon {
                    width: 15px;
                    height: 15px;
                    border-radius: 15px;
                    position: relative;
                    top: -1px;
                    margin-right: 10px;
                }
                &:hover, &:active, &:focus {
                    background: transparent;
                    outline: none;
                    color: $color-main;
                    cursor: pointer;
                }
            }
        }
        &.show {
            > button {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                color: $color-main;
                &:after {
                    @include prefix(transform, rotate(180deg));
                }
            }
            .dropdown-menu {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .login-dropdown {
    	.dropdown-menu {
			width: 250px;
			text-align:center;
            padding: 20px 20px;
        }
        .forgot-password
        {
        	margin-top: 7px !important;
        }
    }
    &.nav-alt {
        .language-dropdown {
            button:after {
                border-top-color: #fff;
            }
            &.show {
                button:after {
                    border-top-color: $color-accent;
                }
            }
        }
        .nav-underline:hover:after {
            display: none;
        }
        .nav-mid ul > li  {
            .nav-dropdown > a:hover, > a:hover {
                color: #fff;
            }
        }
        .nav-right ul > li > a:hover {
            color: #fff;
        }
        ul li a.nav-button {
            padding: 12px 25px;
            border-color: #fff;
            .fa {
                color: #fff;
            }
            &:hover {
                background: #fff;
                color: $color-accent-vibrant;
            }
        }
        .nav-mid, .nav-right {
            .nav-dropdown {
                &:after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    border: 4px solid transparent;
                    border-top-color: #fff;
                    right: 8px;
                    top: 25px;
                    @include transition(opacity .2s);
                }
                &.show {
                    &:after {
                        border-top-color: #fff;
                        opacity: .6;
                    }
                }
            }
            ul > li .nav-dropdown > a, ul > li > a {
                &:not(.nav-button) {
                    @include transition(opacity .2s);
                    &:hover {
                        opacity: .6;
                        color: #fff;
                    }
                    &:focus {
                        color: #fff;
                    }
                }
                &.nav-button {
                    &:hover:focus {
                        color: $color-accent-vibrant;
                    }
                    &:focus {
                        color: #fff;
                    }
                }
            }
        }
    }
    &.nav-forename {
    	background: #5d5786;
    	.nav-underline-accent:before {
    		background: #9d8dff;
    	}
    }
    & {
        padding: 20px 20px;
        .navbar-brand-holder {
            float: left;
            display: inline-block;
        }
        .nav-mid {
            position: absolute;
            width:50%;
            left: 25%;
        }
        .nav-right {
            display: inline-block;
            float: right;
        }
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
}

.nav-underline {
    position: relative;
    display: inline-block;
    &:before {
        content: '';
        position: absolute;
        top: calc(50% + 10px);
        width: 100%;
        left: 0;
        height: 2px;
        background: #fff;
    }
    &:after {
        content: '';
        position: absolute;
        top: calc(50% + 10px);
        width: 100%;
        left: 0;
        height: 2px;
        background: $color-accent-vibrant;
        @include prefix(transform, scaleX(0));
        @include prefix(transform-origin, 0% 50%);
        @include transition(transform .2s ease);
    }
    &:hover:after {
        @include prefix(transform, scaleX(1));
    }
}

.nav-underline-accent {
    position: relative;
    display: inline-block;
    @include transition(color .2s);
    &:before {
        content: '';
        position: absolute;
        top: calc(50% + 10px);
        width: 100%;
        left: 0;
        height: 2px;
        background: $color-accent-vibrant;
    }
}

.nav-dropdown {
    position: relative;
    .dropdown-menu {
        padding: 0;
        display: block;
        margin-top: -2px;
        visibility: hidden;
        opacity: 0;
        min-width: 0;
        left: 50% !important;
        border: none;
        top: 50px !important;
        transform: translateX(-50%) !important;
        box-shadow: 0px 15px 20px rgba(0,0,0,.1);
        @include transition(opacity .2s, visibility .2s);
        &:before {
            position: absolute;
            content: '';
            display: inline-block;
            border: 4px solid transparent;
            border-bottom-color: #fff;
            top: -8px;
            left: calc(50% - 2px);
        }
        .dropdown-item {
            color: $color-accent;
            @include transition(color .2s);
            padding: 10px 20px;
            &:hover, &:focus {
                color: $color-accent-vibrant;
                background: transparent;
            }
            .title {
                color: $color-accent-vibrant;
            }
            .subtitle {
                color: $color-accent;
            }
        }
    }
    &.show .dropdown-menu {
        visibility: visible;
        opacity: 1;
    } 
}

@media (min-width: 992px)
{
	.nav-search-form .tt-menu
	{
		top: 51px !important;
		left: -70% !important;
		width: 170% !important;
		border-top-left-radius: 4px;
	}
}

.nav-display {
	display: inline-block !important;
}