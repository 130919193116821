footer {
    background: #fff;
    padding-top: 60px;
    padding-bottom: 10px;
    margin-top: 150px;
    .footer-title {
        font-size: 13px;
        letter-spacing: 2px;
        color: $color-main;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .footer-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 10px;
            a {
                color: $color-main;
                &:hover {
                    color: $color-accent-vibrant;
                }
            }
        }
        &.footer-list-block {
            li {
                display: block;
                margin: 0 0 10px 0;
            }
        }
    }
    .footer-copyright {
        font-size: 16px;
        font-weight: 400;
        margin-top: 50px;
        opacity: .5;
    }
}