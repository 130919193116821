.sidebar {
    padding: 0 30px 0 30px;
    position: absolute;
    top: 0;
    left: 0;
	width: calc((100vw - 1140px) / 2);
    .sidebar-group {
        margin-bottom: 40px;
        .dropdown.dropdown-select {
            button {
                min-width: 150px;
            }
        }
    }
    .btn-sidebar-toggle {
        background: $color-accent;
        color: #fff;
        font-size: 14px;
        padding: 14px 20px;
        margin: 0 auto; 
        display: none;
        width: 100%;
        text-align: center;
        .icon {
            font-size: 12px;
            position: relative;
            top: -1px;
            left: 7px;
        }
    }
    .sidebar-title {
        font-size: 16px;
        opacity: .5;
        padding: 10px 5px;
    }
    .sidebar-links {
        a {
            padding: 6px 15px;
            color: $color-main;
            position: relative;
            display: inline-block;
            @include transition(color .2s);
            &:before {
                content: '';
                border-top: 2px solid $color-main;
                border-left: 2px solid $color-main;
                width: 10px;
                height: 10px;
                display: inline-block;
                margin-right: 15px;
                @include prefix(transform, rotate(135deg));
            }
            &:hover {
                color: $color-accent-vibrant;
            }
        }
    }
    .sidebar-search-form {
        position: relative;
        input[type="text"] {
            background: #fff;
            border-radius: 4px;
            font-size: 14px;
            padding: 10px 18px;
            border: none;
            width: 100%;
            font-weight: 500;
            @include transition(box-shadow .2s);
            &:focus {
                outline: none;
                box-shadow: 0px 10px 20px -5px transparentize($color-accent, .92);
            }
        }
        button {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            font-size: 18px;
            padding: 0 15px;
        }
    }
    .sidebar-locations {
        font-size: 16px;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                a {
                    display: inline-block;
                    width: calc( 100% - 25px );
                    padding-left: 1.3em;
                    text-indent: -.7em;
                    color: $color-main;
                    margin-bottom: 10px;
                    @include transition(color .2s);
                    &:hover {
                        color: $color-accent-vibrant;
                    }
                    .flag-icon {
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
                ul {
                    padding-left: 27px;
                    li {
                        &:before {
                            content: '';
                            display: inline-block;
                            vertical-align: top;
                            border-bottom: 1px solid #dddce6;
                            border-left: 1px solid #dddce6;
                            height: 15px;
                            width: 15px;
                        }
                        .icon {
                        	margin: 0 5px 0 0;
                        }
                    }
                }
            }
        }
    }
    .sidebar-list {
        list-style: none;
        padding: 0;
        li {
            padding-left: 5px;
            a {
                font-size: 16px;
                padding: 7px 0;
                width: 100%;
                color: $color-main;
                @include transition(color .2s);
                .icon {
                    margin-right: 10px;
                    font-size: 20px;
                    color: $color-accent-vibrant;
                    &.flag-icon {
                        position: relative;
                    }
                }
                &:hover {
                    color: $color-accent-vibrant;
                }
            }
        }
    }
    .sidebar-filter-list {
        list-style: none;
        padding: 0;
        margin: 0;
        .list-item, .list-item > a {
            color: $color-accent;
            font-size: 18px;
            > .icon, > a > .icon {
                color: $color-accent-vibrant;
                font-size: 26px;
                position: relative;
                top: 3px;
                margin-right: 10px;
            }
        }
        > li {
            margin: 10px 0;
            .dropdown {
                margin: 10px 0;
                display: block;
            }
            > a {
                &:hover {
                    color: $color-accent-vibrant;
                }
            }
        }
    }
}

.sidebar-navigation {
	a {
		padding: 10px 14px;
		border-radius: 4px;
		display: inline-block;
		width: 100%;
		font-size: 16px;
		background: #fff;
		color: $color-main;
		box-shadow: 0px 6px 20px transparentize($color-accent-vibrant, .95);
		margin-bottom: 5px;
		.icon {
			margin-right: 10px;
			font-size: 18px;
			position: relative;
			top: 2px;
		}
	}
	a:hover {
		background: #9281FB;
		color: white;
	}
	a.selected {
		font-weight: bold;
	}
}