@font-face {
  font-family: 'icons';
  src:  url('icons.eot?3qwsl3');
  src:  url('icons.eot?3qwsl3#iefix') format('embedded-opentype'),
    url('icons.ttf?3qwsl3') format('truetype'),
    url('icons.woff?3qwsl3') format('woff'),
    url('icons.svg?3qwsl3#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fb-"], [class*=" fb-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="fb-"], [class*=" fb-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fb-trend:before {
  content: "\e94b";
}
.fb-login:before {
  content: "\e932";
}
.fb-user-plus:before {
  content: "\e93e";
}
.fb-chain:before {
  content: "\e941";
}
.fb-search:before {
  content: "\e942";
}
.fb-onoregion:before {
  content: "\e943";
}
.fb-georegion:before {
  content: "\e944";
}
.fb-continent:before {
  content: "\e945";
}
.fb-bishop:before {
  content: "\e946";
}
.fb-archdeacon:before {
  content: "\e947";
}
.fb-priest:before {
  content: "\e948";
}
.fb-map-subdistrict:before {
  content: "\e92a";
}
.fb-map-district:before {
  content: "\e93a";
}
.fb-map-subregion:before {
  content: "\e93b";
}
.fb-page:before {
  content: "\e93c";
}
.fb-forebears:before {
  content: "\e93d";
}
.fb-tree:before {
  content: "\e901";
}
.fb-about:before {
  content: "\e902";
}
.fb-army:before {
  content: "\e904";
}
.fb-baby:before {
  content: "\e905";
}
.fb-barchart:before {
  content: "\e906";
}
.fb-find:before {
  content: "\e907";
}
.fb-bio:before {
  content: "\e908";
}
.fb-business:before {
  content: "\e909";
}
.fb-castle:before {
  content: "\e90a";
}
.fb-cemeteries:before {
  content: "\e90b";
}
.fb-church:before {
  content: "\e90c";
}
.fb-copyright:before {
  content: "\e90e";
}
.fb-couple:before {
  content: "\e90f";
}
.fb-court:before {
  content: "\e910";
}
.fb-credits:before {
  content: "\e911";
}
.fb-db:before {
  content: "\e913";
}
.fb-date:before {
  content: "\e914";
}
.fb-death:before {
  content: "\e915";
}
.fb-dna:before {
  content: "\e918";
}
.fb-face:before {
  content: "\e91b";
}
.fb-field:before {
  content: "\e91c";
}
.fb-fullscreen:before {
  content: "\e91d";
}
.fb-globe:before {
  content: "\e91e";
}
.fb-income:before {
  content: "\e91f";
}
.fb-info-2:before {
  content: "\e920";
}
.fb-info:before {
  content: "\e921";
}
.fb-land:before {
  content: "\e922";
}
.fb-list:before {
  content: "\e923";
}
.fb-map:before {
  content: "\e924";
}
.fb-location_sign:before {
  content: "\e925";
}
.fb-man:before {
  content: "\e926";
}
.fb-news:before {
  content: "\e928";
}
.fb-piechart:before {
  content: "\e92b";
}
.fb-policital:before {
  content: "\e92c";
}
.fb-pray:before {
  content: "\e92d";
}
.fb-privacy:before {
  content: "\e92e";
}
.fb-register:before {
  content: "\e930";
}
.fb-royal:before {
  content: "\e931";
}
.fb-stamp:before {
  content: "\e933";
}
.fb-tablechart:before {
  content: "\e934";
}
.fb-tax:before {
  content: "\e935";
}
.fb-pedigree:before {
  content: "\e936";
}
.fb-user:before {
  content: "\e937";
}
.fb-cd:before {
  content: "\e919";
}
.fb-archive:before {
  content: "\e91a";
}
.fb-money:before {
  content: "\e927";
}
.fb-compass:before {
  content: "\e903";
}
.fb-directories:before {
  content: "\e916";
}
.fb-contact:before {
  content: "\e938";
}
.fb-error:before {
  content: "\e90d";
}
.fb-obituaries:before {
  content: "\e917";
}
.fb-close:before {
  content: "\e949";
}
.fb-send:before {
  content: "\e929";
}
.fb-education:before {
  content: "\e900";
}
.fb-reset:before {
  content: "\e912";
}
.fb-town:before {
  content: "\e93f";
}
.fb-book:before {
  content: "\e939";
}
.fb-resources:before {
  content: "\e92f";
}
.fb-library:before {
  content: "\e940";
}
.fb-pencil:before {
  content: "\e94a";
}
.fb-key:before {
  content: "\e98d";
}
.fb-star:before {
  content: "\e9d9";
}