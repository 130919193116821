button {
    cursor: pointer;
}
.btn {
    display: inline-block;
    border-radius: 4px;
    font-size: 16px;
    padding: 18px 20px;
    border: 2px solid transparent;
    font-weight: 500;
    white-space: normal;
    text-align: center;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    &.btn-primary {
        background: $color-accent-vibrant;
        border-color: $color-accent-vibrant;
        color: #fff;
        @include transition(box-shadow .2s ease-out);
        &:hover {
            box-shadow:0px 10px 18px transparentize($color-accent-vibrant, .7);
        }
        &:focus {
            box-shadow:0px 10px 25px transparentize($color-accent-vibrant, .8);
        }
    }
    &.btn-secondary {
        background: $color-accent;
        border-color: $color-accent;
        color: #fff;
        @include transition(box-shadow .2s ease-out);
        &:hover {
            box-shadow:0px 10px 18px transparentize($color-accent, .7);
        }
        &:focus {
            box-shadow:0px 10px 25px transparentize($color-accent, .8);
        }
    }
    &.btn-empty {
        background: transparent;
        color: #fff;
        border-color: #fff;
        @include transition(color .2s, background .2s);
        &:hover {
            background: #fff;
            color: $color-accent-vibrant;
            box-shadow:0px 10px 18px transparentize($color-accent-vibrant, .7);
        }
    }
    .icon {
        margin-right: 10px;
        font-size: 18px;
    }
    &.btn-small {
        padding: 10px 20px !important;
        font-size: 14px !important;
        .icon {
            font-size: 14px;
        }
    }
    &.btn-label {
        padding: 4px 10px;
        font-size: 14px;
        background: $color-accent-vibrant;
        color: #fff;
    }
    &.btn-micro {
    	padding: 1px 2px;
    	font-size: 8px !important;
    	line-height: 10px;
    }
    &.btn-wide {
        width: 100%;
        text-align: center;
    }
}

.dropdown.dropdown-select {
    color: $color-main;
    font-weight: 500;
    text-align: left;
    margin: 0 20px;
    display: inline-block;
    max-width: 100%;
    @include transition(box-shadow .2s);
    &:hover {
        box-shadow: 0px 16px 30px transparentize($color-main, .93);
    }
    button {
        text-align: left;
        cursor: pointer;
        position: relative;
        background: #fff;
        border-radius: 4px;
        padding: 20px 30px;
        padding-right: 60px;
        font-size: 16px;
        width: 100%;
        font-weight: 500;
        min-width: 250px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        border: 5px solid transparent;
        border-top-color: #ccc;
        right: 26px;
        top: 30px;
        @include prefix(transform-origin, 50% 25%);
        @include transition(transform, .2s ease-out);
    }
    &.show:after {
        @include prefix(transform, rotate(180deg));
    }
    .flag-icon, .fa {
        margin-right: 10px;
    }
    .dropdown-menu {
        width: 100%;
        padding: 0;
        border-radius: 0;
        border: none;
        overflow: hidden;
        max-height: 250px;
        margin: 0;
        overflow-y: auto;
        min-width: 0;
        &[x-placement="bottom-start"] {
            top: -3px !important;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            box-shadow: 0px 10px 20px rgba(0,0,0,.1);        
        }
        &[x-placement="top-start"] {
            top: auto !important;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            box-shadow: 0px -10px 20px rgba(0,0,0,.1);
            transform: none !important;
            bottom: 60px;
        }
        a {
            color: transparentize($color-main, .6);
            font-weight: 500;
            padding: 15px 30px;
            border-radius: 0;
            white-space: nowrap;
            width: 100%;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            @include transition(background-color .2s, color .2s);
            &:hover {
                background: #f2f2f2;
                color: $color-main;            
            }
            &:active, &:focus {
                background: $color-accent-vibrant;
                color: #fff;
            }
            .icon {
                margin-right: 10px;
                position: relative;
                top: -1px;
            }
        }
    }
    &.dropdown-small {
        button {
            padding: 12px 20px;
            font-size: 13px;
        }
        &:after {
            top: 20px;
            right: 15px;
        }
        a {
            color: transparentize($color-main, .6);
            font-weight: 500;
            padding: 12px 20px;
            border-radius: 0;
            font-size: 13px;
            white-space: nowrap;
            width: 100%;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            @include transition(background-color .2s, color .2s);
            &:hover {
                background: #f2f2f2;
                color: $color-main;            
            }
            &:active {
                background: $color-accent-vibrant;
                color: #fff;
            }
            .icon {
                margin-right: 10px;
                position: relative;
                top: -1px;
            }
        }
    }
    &.dropdown-micro {
    	margin: 0;
        button {
        	min-width: 120px;
            padding: 4px 8px;
            font-size: 12px;
        }
        &:after {
            top: 12px;
            right: 12px;
        }
        .dropdown-menu {
        	min-width: 120px;
        	padding: 7px;
        	font-size: 12px;
        	.dropdown-item {
        		padding: 7px 15px;
        	}
        }
    }
    &.dropdown-micro.sort {
		min-width: 250px;
	}
    &.dropdown-white {
        button {
            border: 1px solid #e1e0ea;
        }
        .dropdown-menu {
            border: 1px solid #e1e0ea;
            border-top: 0;
        }
    }
}

.dropdown.dropdown-select.dropdown-search {
    .dropdown-search-input {
        padding: 10px 16px;
        font-size: 14px;
        border-radius: 4px;
        border: none;
        width: calc(100% - 20px);
        margin: 10px 0;
        margin-left: 10px;
        box-shadow: inset 0px 0px 0px 1px #e1e0ea, 0px 6px 16px rgba(0,0,0,.05);
        @include transition(box-shadow .2s ease);
        font-weight: 500;
        &:focus {
            box-shadow: inset 0px 0px 0px 2px $color-accent-vibrant;
            outline: none;
        }
    }
    .dropdown-item.hidden {
        display: none;
    }
    .dropdown-item.active {
        background: $color-accent-vibrant;
        color: #fff;
    }
}

table.table {
    td, th {
        text-align: left;
        padding: 8px 25px;
        a {
            color: $color-accent-vibrant;
            font-weight: 500;
            .icon {
                margin-right: 10px;
            }
        }
    }
    th {
        padding: 12px 25px;
        white-space: nowrap;
    }
    td {
        border-color: #eeedf5;
    }
    th {
        font-weight: 500;
        background: $color-accent-vibrant;
        color: #fff;
        border: none;
        .icon {
            opacity: .5;
            margin-right: 10px;
            @include transition(opacity .2s);
            &:hover {
                opacity: 1;
                cursor: pointer;
            }
        }
    }
    tr {
        @include transition(background .2s);
        &:hover {
            background: #efefef;
        }
    }
}

.form-control-checkbox {
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 8px 12px;
    input[type="checkbox"] {
        position: absolute;
        top:5px;
        z-index: 9999;
        opacity: 0;
        width: 25px;
        height: 25px;
        cursor: pointer;
        + label {
            font-size: 16px;
            cursor: pointer;    
            &:before {
                content: '';
                background: #fff;                
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 3px;
                border: 1px solid #e1e0ea;
                margin-right: 10px;
                position: relative;
                top: 4px;
                box-shadow: 0px 6px 10px rgba(0,0,0,.06);                
            }
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                width: 6px;
                height: 6px;
                background: transparent;
                border-radius: 50%;
                top: 10px;
                left: 5px;
                @include transition(background-color .2s);
            }
        }
    }
    &:hover {
        input[type="checkbox"] + label {
            &:after {
                background: #e1e0ea;
            }
        }
    }
    input[type="checkbox"]:checked + label {
        &:after {
            background: $color-accent-vibrant;
        }
    }
}

.form-control-radio {
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 8px 12px;    
    input[type="radio"] {
        position: absolute;
        left: -9999px;
        + label {
            font-size: 16px;
            cursor: pointer;    
            &:before {
                content: '';
                background: #fff;
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #e1e0ea;
                margin-right: 10px;
                position: relative;
                top: 4px;
                background: #fff;
                box-shadow: 0px 6px 10px rgba(0,0,0,.06);
            }
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                width: 6px;
                height: 6px;
                background: transparent;
                border-radius: 50%;
                top: 10px;
                left: 5px;
                @include transition(background-color .2s);
            }
        }
    }
    &:hover {
        input[type="radio"] + label {
            &:after {
                background: #e1e0ea;
            }
        }
    }
    input[type="radio"]:checked + label {
        &:after {
            background: $color-accent-vibrant;
        }
    }
}

.pell-input {
	border: 2px solid #e1e0ea;
	border-radius: 4px;
	box-shadow: inset 0px 0px 0px 1px #e1e0ea, 0px 6px 16px rgba(0,0,0,.05);
	.pell-actionbar {
		background: transparent;
	}
}

input.form-control, textarea.form-control, .tags-input {
	width: 100%;
    padding: 18px 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    box-shadow: inset 0px 0px 0px 1px #e1e0ea, 0px 6px 16px rgba(0,0,0,.05);
    @include transition(box-shadow .2s ease);
    font-weight: 500;
    &:focus, &.focus {
        box-shadow: inset 0px 0px 0px 2px $color-accent-vibrant;
        outline: none;    
    }
}

input.form-control-sm {
    padding: 9px 10px;
    font-size: 14px ;
}

input.form-control-md {
    padding: 11px 14px;
    font-size: 15px ;
}

.tags-input {
	display: block;
	margin-bottom: 20px;
	input {
		padding: 0;
		padding-left: 10px;
	}
}

.submission-form {
	label {
		display: block;
		margin-bottom: 10px;
		margin-top: 25px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 12px;
	}
	input, .tags-input {
		width: 100%;
	}
	.btn-submit {
		margin-top: 25px;
	}
}

@keyframes color {
  0%   { background: #33CCCC; }
  20%  { background: #33CC36; }
  40%  { background: #B8CC33; }
  60%  { background: #FCCA00; }
  80%  { background: #33CC36; }
  100% { background: #33CCCC; }
}

.d-none {
	display: none !important;
}

.vertical-form {
	font-family: "Work Sans" !important;
	.twitter-typeahead {
		.lang {
			padding: 5px;
			background: #f8f8f8;
			border: 1px solid #f1f1f1;
			border-radius: 5px;
			font-size: 11px;
			small {
				font-size: 8px;
			}
		}
	}
	#parent-name {
		.twitter-typeahead * {
			font-size: 13px;
		}
	}
	table {
		a {
			margin: 0;
			font: 16px "Work Sans" !important;
			line-height: 16px !important;
		}
	}
	.row {
		display: -webkit-flex;
		display: flex;
		align-items: center;
		&.row-top {
			align-items: flex-start;
		}
	}
	h5 {
		margin: 0;
	}
	.search-control {
		box-shadow: none !important;
		border: 1px solid #bbb !important;
		&.search-control-sm {
			height: 24px;
			padding: 22.5px 10px !important;
			font-size: 13px;
			line-height: 24px;
		}
	}
	.tt-menu {
		z-index: 999 !important;
	}
	input[type="text"] {
		border-radius: 0 !important;
		font: 13px "Work Sans" !important;
		line-height: 13px !important;
		color: #777 !important;
	}
	table {
		th, td {
			padding: 8px 15px 8px 15px;
		}
		.form-control-tiny {
			width: 40px;
			padding: 5px;
			font-size: 12px;
		}
	}
	.gender {
		display: inline-block;
		width: 16px;
		height: 16px;
		margin-left: 5px;
		vertical-align: middle;
		border: 1px solid #bbb;
		&.f {
			background: #ff8989;
		}
		&.m {
			background: #8bd0f4;
		}
		&.u {
			background: #ccc;
		}
	}
	.variant-controls {
		margin-top: 20px;
		.dropdown button {
			height: 28px !important;
			margin-top: 1px;
			border: 0;
		}
		.dropdown-filter {
			padding: 0 5px 5px 5px;
			input {
				border: 1px solid #ddd;
			}
			&:hover {
				background: none !important;
			}
		}
	}
	.components {
		padding: 15px;
		background: #fbfbfb;
		border: 1px solid #ddd;
	}
	.component {
		padding: 15px;
		background: #fff;
		border: 1px solid #ddd;
		.twitter-typeahead * {
			font-size: 13px;
		}
		.prefix, .suffix {
			padding: 0 !important;
			h5 {
				margin: 10px 0 0 0;
			}
		}
	}
	.component:not(:first-child) {
		margin-top: 15px;
	}
	.glossary {
		padding: 0 15px;
		input {
			height: 13px;
			margin: 15px 15px 0 0;
		}
		label {
			display: inline;
			font: 13px "Work Sans" !important;
			line-height: 13px;
		}
	}
	.bootstrap-select button {
		padding: 15px 11px !important;
	}
	.dropdown {
		border: 1px solid #bbb !important;
		.dropdown-item {
			font: 13px "Work Sans" !important;
			line-height: 13px !important;
		}
		button {
			font: 13px "Work Sans" !important;
			line-height: 13px !important;
			color: #777 !important;
		}
	}
	.bootstrap-tagsinput {
		border: 1px solid #bbb !important;
		border-radius: 0 !important;
		* {
			font: 13px "Work Sans" !important;
		}
		.tt-input {
			height: 13px !important;
			.twitter-typeahead {
				min-width: 100%;
			}
		}
		.ethnicity-controls {
			.twitter-typeahead {
				width: 328px;
				.tt-input {
					height: 13px !important;
				}
			}
		}
	}
	caption {
		caption-side: top;
		margin-bottom: 5px;
	}
	.pulse {
		animation: color 4s infinite linear;
	}
	.ethnicity-us, .ethnicity-il, .ethnicity-sa {
		height: 36px !important;
		border: 1px solid #bbb;
		div {
			display: inline-block;
			overflow: hidden;
			color: #fff;
			span {
				display: block;
				padding: 5px 10px;
			}
			&.white span {
				color: #000 !important;
			}
		}
	}
	.ethnicity-il {
		overflow-x: scroll;
		.inner {
			width: 5000px;
		}
		* {
			color: #000;
		}
	}
	.modal-dialog {
		max-width: 70%;
		li
		{
			font-size: 16px !important;
			line-height: 20px !important;
		}
	}
}

#bulk {
	.dropdown-micro {
		min-width: 160px !important;
	}
}