.animated{
    -webkit-animation-duration:2s;
    animation-duration:.2s;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both
}

.dropdown-menu {
    animation: dropdownMenuAnim .2s;
    transform-origin: 50% 0;
}

@-webkit-keyframes dropdownMenuAnim {
    from {opacity: 0;}
    to {opacity: 1;}
}

/* Standard syntax */
@keyframes dropdownMenuAnim {
    from {opacity: 0;}
    to {opacity: 1;}
}