.pell{
    border:1px solid hsla(0,0%,4%,.1)
}
.pell,.pell-content{
	background: #fff;
    box-sizing:border-box;
}
.pell-content{
    height:300px;
    outline:0;
    overflow-y:auto;
    padding:10px
}
.pell-actionbar{
    background-color:#fff;
    border-bottom:1px solid hsla(0,0%,4%,.1)
}
.pell-button{
    background-color:transparent;
    border:none;
    cursor:pointer;
    height:30px;
    outline:0;
    width:40px;
    vertical-align:bottom
}
.pell-button-selected{
    background-color:#f0f0f0
}

.tags-input {
 	position: relative;
 	z-index: 99;
    display:inline-block;
    padding:0 2px;
    background:#FFF;
    border:1px solid #CCC;
    border-radius:2px;
    box-shadow:inset 0 1px 2px rgba(0,0,0,.1)
}
.tags-input .tag{
    display:inline-block;
    background:#fff;
    color:#444;
    padding:0 4px;
    margin:2px;
    border:1px solid #acacac;
    border-radius:2px;
    font:inherit;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:pointer;
    transition:all .1s ease
}
.tags-input .tag.selected{
    background-color:#ddd;
}
.tags-input .tag.dupe{
    -webkit-transform:scale3d(1.2,1.2,1.2);
    transform:scale3d(1.2,1.2,1.2);
    background-color: #fcc;
    border-color:#700;
}
.tags-input input{
    -webkit-appearance:none!important;
    -moz-appearance:none!important;
    appearance:none!important;
    display:inline-block!important;
    width: 103% !important;
    padding:3px;
    margin:0!important;
    background:0 0!important;
    border:none!important;
    box-shadow:none!important;
    font:inherit!important;
    font-size:100%!important;
    outline:0!important
}
.tags-input .selected~input{
    opacity:.3
}

.autocomplete-suggestions {
    /* core styles should not be changed */
     position: absolute;
     display: none;
     z-index: 1;
     max-height: 254px;
     overflow: hidden;
     overflow-y: auto;
     box-sizing: border-box;
}