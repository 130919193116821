/* ------------------------------------------------------------------------------
*
*  # Bootstrap tags input
*
*  Styles for tagsinput.js - tags input for Bootstrap
*
* ---------------------------------------------------------------------------- */
.bootstrap-tagsinput {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 45px;
  padding: 0;
  font-size: 15px;
  line-height: 1.42857143;
  color: #2c3e50;
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #dce4ec;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.has-error .bootstrap-tagsinput {
  border-color: #e74c3c !important;
}
.bootstrap-tagsinput.focus {
  border-color: #2c3e50;
  outline: 0;
  box-shadow: none;
}
.bootstrap-tagsinput input {
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 5px 11px;
  margin-top: 2px;
  margin-left: 2px;
  width: auto !important;
  min-width: 100px;
  font-size: 15px;
  line-height: 1.6666667;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .twitter-typeahead {
  width: auto;
}
.bootstrap-tagsinput .tt-menu {
  margin-top: 5px;
  min-width: 200px;
}
.bootstrap-tagsinput .tag {
  margin: 1px 0 0 3px;
  border: 0;
  border-radius: .25em;
  padding: 5px 11px;
  padding-right: 30px;
  float: left;
  font-size: 15px;
  line-height: 1.6666667;
  font-weight: 400;
  text-transform: none;
  position: relative;
  background-color: #18bc9c;
  color: #fff;
}
.has-error .bootstrap-tagsinput .tag {
  background-color: #e74c3c !important;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  cursor: pointer;
  color: inherit;
  position: absolute;
  top: 50%;
  right: 11px;
  line-height: 1;
  margin-top: -5.5px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: '\e94a';
  font-family: 'icons' !important;
  font-weight: 900;
  display: block;
  font-size: 13px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}