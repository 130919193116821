@mixin bp($point) {
    
    $bp-mobile: "(max-width: 768px)";
    $bp-mobile-l: "(max-width: 991px)";
    $bp-tablet: "(max-width: 1170px)";
    $bp-laptop: "(max-width: 1366px)";
    $bp-laptop-l: "(max-width: 1600px)";
    
    $bp-name-gen-s: "(max-width: 1780px)";
  
    @if $point == mobile {
      @media #{$bp-mobile} { @content; }
    }
    @else if $point == tablet {
      @media #{$bp-tablet} { @content; }
    }
    @else if $point == mobile-l {
    @media #{$bp-mobile-l} { @content; }
    }
    @else if $point == laptop {
      @media #{$bp-laptop}  { @content; }
    }
    @else if $point == laptop-l {
        @media #{$bp-laptop-l}  { @content; }
    }
    @if $point == name-gen-s {
        @media #{$bp-name-gen-s}  { @content; }
    }
  }

@include bp(tablet) {
    nav.top-nav {
        padding: 20px 20px;
        .nav-mid {
            width: auto;
            left: auto;
            position: relative;
            display: inline-block;
            float: left;
        }
        .nav-mid ul > li, .nav-right ul > li {
            .dropdown > a, > a {
                font-size: 11px;
                letter-spacing: 1px;
                margin: 0 10px;
            }
        }
        .nav-right ul .nav-search-form {
            padding-right:20px;
            .btn-search {
                right: 20px;
            }
        }  
    }
    .nav-dropdown {
     	margin-right: 8px !important;
    }
    &::after {
    	right: -4px !important;
    }
    .login-dropdown::after {
    	top: 23px !important;
    }
    .search-button::after {
    	right: 21px !important;
    }
}

@include bp(mobile-l) {
	.nav-display {
    	display: block !important;
    }
    nav.top-nav, nav.top-nav.nav-full {
        width: 100%;
        padding: 10px 20px;
        > .container {
            padding: 0;
            max-width: 100% !important;
        }
        .nav-mid ul > li, .nav-right ul > li {
            .dropdown > a, > a {
                font-size: 11px;
                letter-spacing: 1px;
                margin: 0 10px;
            }
            .dropdown {
                .dropdown-menu {
                    border-radius: 0;
                    position: relative !important;
                    display: none;
                    top: 0 !important;
                    left: 0 !important;
                    transform: none !important;
                    margin-top: 0;
                    width: 100%;
                    background: transparent;
                    box-shadow: none;
                    padding-left: 10px;
                    border-top: 1px solid rgba(255,255,255,.1);
                    &:before {
                        display: none;
                    }
                    .dropdown-item {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 500;
                        a {
                            padding: 5px;
                        }
                        .icon {
                            display: none;
                        }
                        .content {
                            padding: 0;
                            .title {
                                color: #fff;
                                font-size: 12px;
                                font-weight: 500;
                            }
                            .subtitle {
                                display: none;
                            }
                        }
                    }
                }
                &.show {
                    .dropdown-menu {
                        display: block;
                    }
                }
                &.login-dropdown {
                    .dropdown-menu {
                        padding: 10px 10px;
                        .form-control-input {
                            width: 100%;
                            padding: 8px 15px;
                            font-size: 12px;
                        }
                        .form-control-checkbox {
                            padding: 0;
                            label {
                                color: #fff;
                                font-size: 12px;
                                &:after {
                                    top: 9px;
                                }
                            }
                        }
                        .btn {
                            margin: 5px 0;
                        }
                        .forgot-password {
                            padding: 0;
                            margin-bottom: 10px;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .navbar-brand-holder {
            float: none;
            display: block;
            width: 100%;
            .navbar-brand {
                font-size: 28px;            
            }
            img {
                width: 128px;
                height: 42px;
                top: -3px;
            }
            .language-dropdown {
                margin-left: 0;
                position: relative;
                top: -3px;
                margin-left: 10px;
            }
        }
        .navigation-responsive {
            position: absolute;
            top:100%;
            left: 0;
            width: 100%;
            opacity: 0;
            background: $color-accent;
            display: none;
            @include prefix(transform, translateY(-20px));
            .nav-mid, .nav-right {
                float: none;
                display: block;
                .nav-item {
                    display: block;
                    text-align: left;
                    .nav-underline, .nav-underline-accent {
                        &:before, &:after {
                            display: none;
                        }
                    }
                    .dropdown a, a {
                        padding: 10px 15px;
                        margin: 0;
                        width: 100%;
                        > .icon {
                            display: none;
                        }
                    }
                    .nav-button {
                        border: none;
                    }
                    .nav-search-form {
                        padding: 10px 15px;
                        .form-control {
                            padding: 9px 18px;
                            padding-left: 40px;
                            width: 100%;
                        }
                        .icon {
                            display: block;
                        }
                        .btn-search {
                            right: auto;
                            left: 14px;
                            top: 13px;
                            font-size: 14px;
                        }
                    }
                }
                .list-separator {
                    display: none !important;
                }
            }
			.nav-dropdown::after {
				top: 8px !important;
			}
        }
        .nav-toggle {
            float: right;
            padding: 8px 6px;
            @include transition(transform .25s ease-out);
            @include prefix(transform-origin, 50% 50%);
            span {
                display: block;
                width: 21px;
                height: 2px;
                background: $color-accent-vibrant;
                margin: 4px 0;
                @include transition(transform .25s ease-out, opacity .25s);
                &:nth-child(1) {
                    @include prefix(transform-origin, 0 50%);
                }
                &:nth-child(3) {
                    @include prefix(transform-origin, 0% 50%);
                }
            }
        }
        &.nav-alt {
            .nav-toggle {
                span {
                    background: #fff;
                }
            }
        }
        &.menu-show {
            background: $color-accent;
            .nav-toggle {
                float: right;
                padding: 8px 6px; 
                @include prefix(transform, rotate(180deg) translateX(3px));
                span {
                    &:nth-child(1) {
                        @include prefix(transform, rotate(45deg) translateX(-2px) translateY(-2px));
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        @include prefix(transform, rotate(-45deg) translateX(0px) translateY(0px));
                    }
                }
            }
        }
    }
    .translation-suggestion {
        padding: 10px 20px;
        font-size: 14px;
        a {
            font-size: 14px;
        }
    }
    .secondary-navigation {
        text-align: left;
        padding: 10px 10px;
        overflow-x: auto;
        ul {
            white-space: nowrap;
            li {
                margin: 0 5px;
                a {
                    font-size: 14px;
                }
            }
        }
    }
	.map-filters {
		.dropdown.dropdown-select {
			margin-bottom: 10px;
			max-width: initial;
		}
		.btn {
			padding: 8px 12px;
			font-size: 14px;
			width: 100%;
		}
	}
    .link-list {
        padding-left: 40px;
        font-size: 14px;
        margin: 15px 0;
        li {
            &:before {
                top: 6px;
                left: -40px;
            }
            a {
                font-size: 14px;
            }
        }
    }
    .content-box-content {
		a, blockquote, li, p {
			line-height: 30px !important;
			letter-spacing: 1.2px  !important;
			font-size: 18px !important;
		}
	}
	.small-text {
		a, blockquote, li, p {
			line-height: 20px !important;
			letter-spacing: 1px;
			font-size: 12px !important;
		}
	}
	.register-form-wrapper {
		margin-top: 30px;
	}
	#name-card {
		margin-bottom: 10px;
	}
}

/* ========================== */
/* ======= PAGE STYLES ======*/
/* ========================== */

@include bp(mobile-l) {
    section.search-section {
        padding-bottom: 50px;
        .search-title {
            padding: 0 10px;
            font-size: 24px;
        }
        .search-form {
            padding: 10px 15px;
            .twitter-typeahead {
                .form-control {
                    padding: 15px 20px;
                    padding-right: 105px;
                    font-size: 14px;
                }
            }
            .tt-menu {
                .tt-suggestion {
                    text-align: left;
                    padding: 4px 8px;
                    font-weight: 500;
                    font-size: 14px;
                    @include transition(background .2s);
                    cursor: pointer;
                    &.tt-cursor, &:hover {
                        background: #fff;
                    }
                }
            }
            .icon {
                display: none;
            }
            .search-button {
                padding: 0 25px;
                padding-left: 15px;
                font-size: 11px;
                &:after {
                    top: 22px;
                    right: 11px;
                }
            }
        }
    }
    .resource-categories {
        padding: 40px 0;
        a {
            margin: 5px 5px;
            font-size: 12px;
            .icon {
                font-size: 18px;
            }
        }
    }
    .search-button::after {
    	right: 10px !important;
    }
    .banner-h {
		width: 324px;
		height: 324px;
		margin: auto;
	}
}

@include bp(tablet) {
	.dropdown.dropdown-select {
		&:after {
			top: 20px;
			right: 16px !important;
		}
	}
    .home-grid {
        .grid-item {
            font-size: 24px;
            .grid-content {
                padding: 20px 20px;
            }
            .dropdown {
                margin: 0;
            }
            .icon-big {
                font-size: 32px;
                margin-bottom: 10px;
            }
            &.grid-item-normal {
                height: auto !important;
                .dropdown.dropdown-select  {
                    width: 100%;
                    max-width: 350px;
                    button {
                        min-width: 0;
                        width: 100%;
                    }   
                }
            }
        }
    }
    .banner-h {
		width: 324px;
		height: 324px;
		margin: auto;
	}
}

@include bp(mobile-l) {
    section.main-content {
        .section-title {
            margin: 10px 0;
            font-size: 24px;
        }
    }
    .home-grid {
        .grid-item {
            font-size: 18px;
            height: auto !important;            
            .dropdown {
                margin: 20px 0;
            }
            &.grid-item-3, &.grid-item-4, &.grid-item-5 {
                .grid-content {
                    padding: 25px 15px;
                    font-size: 16px;
                    text-align: center !important;
                    a {
                        font-weight: 500;
                    }
                }
            }
            &.grid-item-normal {
                text-align: center;
                padding: 0;
                .title {
                    padding: 15px 10px;
                    padding-top: 0;
                    font-size: 18px;
                }
                .search-block {
                    .search-block-title {
                        font-size: 16px;
                    }
                }
                .grid-list {
                    a {
                        font-size: 16px;
                        margin-bottom: 5px;
                        .icon {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
    .home-news {
        .news-article {
            text-align: left;
            margin-bottom: 30px;
            padding: 5px 15px;
            .heading {
                font-size: 20px;
                .date {
                    left: 0;
                    right: auto;
                    bottom: -20px;  
                    font-size: 12px;
                    letter-spacing: 1px;
                }
            }
        }
    }
    .read-more-link {
        padding: 15px 15px;
    }
    .breadcrumbs {
        display: block;
        ul {
            margin: 10px 0;
            width: 100%;
            li, li:not(:first-child) {
                display: block;
                margin: 0;
                a {
                    width: 100%;
                    text-align: left;
                    display: block;
                    font-size: 14px;
                    border-radius: 0;
                    padding: 6px 6px;
                    position: relative;
                    &:before, &:after {
                        display: none;
                    }
                }
            }
            li:not(:first-child) {
                a {
                    border-top: 1px solid rgba(0,0,0,.1);
                }
            }
        }
    }
}

@include bp(mobile) {
	.navigation-responsive {
		display: none;
	}
    p {
        font-size: 14px;
    }
    p.large, p.larger {
        font-size: 16px;
        a:not(.btn) {
            font-size: 16px;
        }
    }
    .table-navigation-helper, .table-navigation {
        text-align: center !important;
        padding: 10px 15px;
        &.table-navigation-helper {
            padding: 10px 5px;
        }
    }
    .ancestor-search {
    	width: auto;
		height: auto;
		margin: 0 12px;
		.title {
			padding: 12px 0 4px;
		}
		.subtitle {
			padding: 0 0 8px;
		}
		.title-sm {
			padding: 18px 0 12px;
			font-weight: 500;
		}
		.title-md {
			height: auto;
			padding: 12px 0;
			font-weight: 500;
		}
		.carousel {
			.carousel-item div {
				width: 46%;
			}
		}
		.myh-splash {
			padding: 0;
			background: linear-gradient( #896d58, #d1b397 );
		}
		input
		{
			width: 45% !important;
		}
		form .btn {
			width: 93.5%;
			margin-top: 12px;
		}
		.view-all {
			margin-bottom: 12px;
		}
    }
    footer {
        padding: 15px 0px;
        .footer-title {
            margin-bottom: 10px;
        }
        .footer-list {
            margin-bottom: 20px;
            li {
                margin-bottom: 5px;
                margin-right: 10px;
            }
        }
        .footer-copyright {
            margin-top: 20px;
        }
    }
    .big-form {
        .input-holder {
            .input-main {
                font-size: 14px;
                padding: 15px 20px;
                padding-right: 85px;
            }
            .icon {
                top: 19px;
                left: 17px;
                font-size: 14px;
            }
            button, button.search-button {
                font-size: 11px;
                padding: 0 25px;
                padding-left: 15px;
                font-size: 11px;
                &:after {
                    top: 21px;
                    right: 11px;
                }
            }
            &.input-with-icon {
                .input-main {
                    padding-left: 40px;
                }
            }
        }
    }
    .dropdown.dropdown-select {
        display: block;
        max-width: 350px;
        margin-left: auto !important;
        margin-right: auto !important;
        button {
            padding: 12px 20px;
            font-size: 13px;
        }
        &:after {
            top: 20px;
            right: 16px !important;
        }
        .dropdown-menu {
            &[x-placement="top-start"] {
                bottom: 40px;
            }
        }
        .dropdown-menu a {
            color: transparentize($color-main, .6);
            font-weight: 500;
            padding: 12px 20px;
            border-radius: 0;
            font-size: 13px;
            white-space: nowrap;
            width: 100%;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            @include transition(background-color .2s, color .2s);
            &:hover {
                background: #f2f2f2;
                color: $color-main;            
            }
            &:active {
                background: $color-accent-vibrant;
                color: #fff;
            }
            .icon {
                margin-right: 10px;
                position: relative;
                top: -1px;
            }
        }
    }
    .surname-search-results {
        .surname-search-result {
            margin-bottom: 10px;
            display: block;
            padding: 15px 5px;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0px 10px 30px transparentize(#8b889e, .9);
            color: $color-main;
            @include transition(box-shadow .2s ease);
            .surname {
                font-size: 18px;
            }
            .match {
                font-size: 14px;
                margin-bottom: 15px;
            }
            .detail-title {
                font-size: 14px;
                margin-top: 10px;
            }
            .detail-value {
                font-size: 16px;
            }
        }
    }
    .register-holder {
        margin: 40px 0;
        padding: 15px 25px;
        .content-box-title {
            padding-top: 15px;
            padding-bottom: 0;
        }
        .content-box-content {
            padding: 0;
            a, blockquote, li, p {
				line-height: 30px !important;
				letter-spacing: 1.2px  !important;
				font-size: 18px !important;
			}
        }
        .form-control {
            width: 100%;
            margin: 10px 0;
            font-size: 14px;
            padding: 14px 20px;
        }
        .btn {
            margin: 10px 0;
            font-size: 14px;
            padding: 14px 20px;
        }
    }
    .content-box.blog-post {
        .blog-header {
            width: 100%;
            padding: 0px;
        }
        .heading {
            font-size: 20px;
        }
        .blog-details {
            margin-top: 0;
            margin-bottom: 20px;
            .blog-detail {
                font-size: 14px;
                margin-right: 10px;
                color: $color-main;
                .icon {
                    font-size: 16px;
                }
            }
        }
        .blog-img-holder {
            margin: 30px 0;
        }
        .content-box-content {
            padding: 20px 20px;
        }
        .blog-img  .blog-img-description {
            font-size: 14px;
            text-align: left;
        }
        .blog-footer {
            font-size: 14px;
            margin-top: 20px;
            a {
                display: inline-block;
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }
    .mobile-only {
		display: block;
	}
	.desktop-only {
		display: none;
	}
	.small-text {
		a, blockquote, li, p {
			line-height: 20px !important;
			letter-spacing: 1px;
			font-size: 12px !important;
		}
	}
	.register-form-wrapper {
		margin-top: 30px;
	}
	.banner-h {
		width: 324px;
		height: 324px;
		margin: auto;
	}
}

@include bp(laptop) {
	.nav-mid {
		width: auto !important;
		left: auto !important;
		position: relative;
		display: inline-block;
		float: left;
	}
    .page-title {
        padding: 20px 0;
        font-size: 28px;
    }
    .dropdown.dropdown-select button {
        display: block;
        min-width: 0;
    }
}

@include bp(laptop-l) {
.nav-mid {
		width: auto !important;
		left: auto !important;
		position: relative;
		display: inline-block;
		float: left;
	}
    .sidebar {
        padding: 30px 15px;
        position: relative;
        width: 540px;
        margin: 0 auto;
        top: 0 !important;
        max-width: 100%;
        .btn-sidebar-toggle {
            display: block;
            position: relative;
            z-index: 5;
            .icon {
                @include transition(transform .2s ease-out);
                @include prefix(transform-origin, 50% 50%);
            }
        }
        .sidebar-inner {
            display: none;
            width: calc(100% - 30px);
            position: absolute;
            z-index: 4;
            max-height: 300px;
            overflow-y: auto;
            border: 1px solid #dddddd;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            top: 80px;
            box-shadow: 0px 6px 15px rgba(0,0,0,.06);
            padding: 10px 10px;
            background: #f3f3f3;
            .sidebar-group {
                margin-bottom: 10px;
                .sidebar-title {
                    font-size: 16px;
                }
            }
        }
        &.sidebar-show {
            .btn-sidebar-toggle {
                .icon {
                    @include prefix(transform, rotate(180deg));
                }
            }
        }
    }
}

@include bp(tablet) {
    .content-box {
        margin: 15px 0;
        .content-box-title {
            padding:15px 15px;
            .heading {
                font-size: 20px;
                .icon {
                    font-size: 26px;
                }
            }
        }
        .tablet-slim {
        	padding: 10px;
        }
        .statistic-number {
        	font-size: 24px;
        }
        .content-list {
            .content-list-item {
                padding:20px 20px;
                .title {
                    font-size: 18px;
                }
                .body {
                    font-size: 12px;
                    line-height: 1.2;
                }
            }
        }
    }
    .sidebar {
        .sidebar-filter-list {
            .list-item, .list-item > a {
                font-size: 14px;
                > .icon, > a > .icon {
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
            > li {
                margin: 5px 0;
                .dropdown {
                    margin: 5px 0;
                    display: block;
                }
                > a {
                    &:hover {
                        color: $color-accent-vibrant;
                    }
                }
            }
        }
    }
    footer {
        margin-top: 30px;
    }
}

/* ========================== */
/* ======= NAME GENERATOR ======*/
/* ========================== */

@include bp(laptop) {
	#name-generator {
		.name-sidebar {
			width: 25% !important;
		}
	}
}

@include bp(tablet) {
	#name-generator {
		.name-sidebar {
			width: 30% !important;
		}
		
	}
}

@include bp(mobile-l) {
	#name-generator {
		.name-sidebar {
			width: 40% !important;
		}
		#fav {
			width: 100%;
			label {
				line-height: 30px;
			}
			.star-holder {
				margin-bottom: 15px;
			}
		}
		.col-lg-4:nth-of-type(n+3) {
			margin-top: 15px;
		}
	}
}

@include bp(mobile) {
	#name-generator {
		.name-sidebar {
			width: 100% !important;
		}
	}
}

@include bp(name-gen-s) {
	#name-generator {
		.name-sidebar {
			display: none;
			position: fixed;
			z-index: 1002;
			top: 0;
			left: 0;
			width: 20%;
			height: 100%;
			margin: 0;
			padding: 0;
			background: #fbfaff;
			.name-lists {
				height: 100%;
				.close {
					display: block;
					padding: 10px 0 10px 0;
					background: #fdfdfd;
					text-align: center;
					cursor: pointer;
					@include transition(all .2s);
					&:hover {
						background: #fafafa;
					}
					.fb-close {
						float: none;
						margin-left: 7px;
					}
				}
			}
		}
		#fav {
			#name-list-toggle {
				display: block;
				margin-top: 15px;
				text-align: center;
			}
		}
	}
}