a {
    display: inline-block;
    text-decoration: none;
    &:focus, &:active, &:hover {
        outline: none;
        text-decoration: none;
    }
}

button {
    background: transparent;
    border: none;
    &:focus {
        outline: none;
    }
}

input {
    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 4px transparentize($color-accent-vibrant, .8);
    }
}